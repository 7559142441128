import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonService } from '../common/common.service';
import { ApiResponse } from '../../model/api-response.models';
import { catchError, retry } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AdminService {
  private apiURL: string = environment.API_ENDPOINT;

  constructor(private http: HttpClient, private commonService: CommonService) {}

  httpOptions = {
    headers: new HttpHeaders({
      Authorization: 'bearer ' + localStorage.getItem('token'),
    }),
  };

  getAllAdminModules(obj) {
    return this.http
      .post<ApiResponse>(
        this.apiURL + 'admin/get_admin_modules',
        obj,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.commonService.handleError));
  }

  createAdmin(obj) {
    return this.http
      .post<ApiResponse>(
        this.apiURL + 'admin/add_super_admins',
        obj,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.commonService.handleError));
  }
  updateAdmin(obj) {
    return this.http
      .post<ApiResponse>(
        this.apiURL + 'admin/update_super_admins',
        obj,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.commonService.handleError));
  }
  updateFCM(obj) {
    return this.http
      .post<ApiResponse>(
        this.apiURL + 'user/update_FCMToken',
        obj,
        this.httpOptions
      )
      .pipe(retry(1), catchError(this.commonService.handleError));
  }
}
